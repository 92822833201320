import {
  getAppNames,
  registerApplication,
  start,
  unloadApplication,
  unregisterApplication,
} from "single-spa";
import {
  constructApplications,
  constructRoutes,
  constructLayoutEngine,
} from "single-spa-layout";
import authApplication from "./views/auth/authenticated-mfe-layout.html";
import { removeTokens, verifyToken } from "./utils/auth.utils";
import Cookies from "universal-cookie";
import { verifyTenant } from "./utils/permission.utils";
import homeAppMapping from "./config/home-app.config.json";
import styles from "./styles/main.html";

const cookie = new Cookies();

document.head.innerHTML += styles;

const routes = constructRoutes(authApplication);
const applications = constructApplications({
  routes,
  loadApp: ({ name }) => {
    return System.import(name);
  },
});

const layoutEngine = constructLayoutEngine({
  routes,
  applications,
});

applications.forEach((app) => {
  const tenant = cookie.get("tenant");
  registerApplication({
    name: app.name,
    app: app.app,
    activeWhen: () => {
      if (app.name.includes("home") && homeAppMapping[tenant] != app.name) {
        return false;
      }
      if (app.name.includes("navbar") && tenant != "ctobo") {
        return false;
      }

      return true;
    },
  });
});

window.addEventListener(
  "single-spa:before-app-change",
  async (event: CustomEvent) => {
    const validTenant = await verifyTenant();
    const tenant = cookie.get("tenant");
    const apps = getAppNames().filter(
      (a) => a.includes("home") && a !== homeAppMapping[tenant] && validTenant,
    );

    for (const app of apps) {
      unregisterApplication(app);
      unloadApplication(app);
    }
  },
);

window.addEventListener("single-spa:routing-event", async (evt) => {
  const tenant = cookie.get("tenant");
  if (!tenant) {
    await verifyTenant();
  }
  const validToken = await verifyToken();

  if (!validToken) {
    removeTokens();
    window.location.replace("/login");
  }
});

layoutEngine.activate();
start();
