import axios from "axios";
import Cookie from "universal-cookie";
import env from "../environment";
import { removeTokens } from "./auth.utils";

const { AUTH_SERVICE } = env;

const cookie = new Cookie();

export const verifyTenant = async () => {
  const token = cookie.get("access_token");
  const tenant = cookie.get("tenant");

  const permission = await axios.get(
    `${AUTH_SERVICE}/v1/authenticate/token/permission/${token}`,
  );

  if (permission.data?.valid) {
    const { userPermission } = permission.data;

    if (userPermission.permission.systemId === tenant) {
      return true;
    }

    cookie.set("tenant", userPermission.permission.systemId);
  } else {
    removeTokens();
  }

  return window.location.reload();
};
