const { APP_NAME, AUTH_URL, AUTH_SERVICE, APOLLO_URL, APOLLO_SERVICE, ENV } =
  process.env;

const environment = {
  ENV: ENV ?? "local",
  APP_NAME: APP_NAME ?? "cto-bo-root",
  AUTH_URL: AUTH_URL ?? "/api/auth",
  AUTH_SERVICE: AUTH_SERVICE ?? "/api/auth",
  APOLLO_URL: APOLLO_URL ?? "/api/gw",
  APOLLO_SERVICE: APOLLO_SERVICE ?? "/api/gw",
};

export default environment;
