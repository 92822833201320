import axios from "axios";
import jwtDecode from "jwt-decode";
import env from "../environment";
import Cookie from "universal-cookie";

interface IToken {
  token_secret: string;
  user_name: string;
  iat: number;
  exp: number;
  iss: string;
}

const cookie = new Cookie();

export const verifyToken = async () => {
  const token = cookie.get("access_token");

  if (!token) return false;

  const decoded: IToken = jwtDecode(token);
  if (!decoded || !decoded.hasOwnProperty("exp")) return false;

  if (decoded.exp > Date.now() / 1000) {
    return await validateToken(token);
  } else {
    return refreshToken();
  }
};

export const validateToken = async (token: string) => {
  try {
    const res = await axios.post(
      `${env.AUTH_SERVICE}/v1/authenticate/valid/token`,
      {
        token: token,
      },
    );

    if (res.data) {
      const { valid } = res.data;
      if (valid) {
        refreshToken();
      }
      return valid;
    }
    return false;
  } catch (err) {
    return false;
  }
};

const refreshToken = async () => {
  try {
    const refreshToken = cookie.get("refresh_token");
    const decoded: IToken = jwtDecode(cookie.get("access_token"));
    const refreshedToken = await axios.get(
      `${env.AUTH_SERVICE}/v1/authenticate/refresh/${refreshToken}/${decoded.user_name}`,
    );

    if (refreshedToken.data.jwt && refreshedToken.data.refresh_token) {
      cookie.set("access_token", refreshedToken.data.jwt);
      cookie.set("refresh_token", refreshedToken.data.refresh_token);
      location.reload();
      return true;
    }
    return false;
  } catch (err) {
    return false;
  }
};

export const removeTokens = () => {
  cookie.remove("access_token");
  cookie.remove("refresh_token");
  cookie.remove("tenant");
};
